<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <ul class="list-group">
                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">
                        <span class="font-weight-700">{{ translate('Customer details', 'clients') }} <small>({{ company.data.id }})</small></span>
                        <button class="btn btn-primary rounded-pill py-0" v-if="editMode" @click="save">{{ translate('Save changes', 'global') }}</button>
                        <button class="btn border rounded-pill py-0" v-else @click="editMode = true">{{ translate('Edit', 'global') }}</button>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('Company name', 'clients') }}:</label>
                        <input v-model="company.data.name" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('name', v.target.value)" :disabled="!editMode">
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('Email', 'clients') }}:</label>
                        <input v-model="company.data.email" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('email', v.target.value)" :disabled="!editMode">
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('Phone', 'clients') }}:</label>
                        <input v-model="company.data.phone" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('phone', v.target.value)" :disabled="!editMode">
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('Street', 'clients') }}:</label>
                        <input v-model="company.data.street_number" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('street_number', v.target.value)" :disabled="!editMode">
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('ZIP code', 'clients') }}:</label>
                        <input v-model="company.data.zip_code" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('zip_code', v.target.value)" :disabled="!editMode">
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between">
                        <label>{{ translate('VAT', 'clients') }}:</label>
                        <input v-model="company.data.vat" class="bg-white"
                               :class="editMode === true ? 'border rounded' : 'border-0'"
                               @input="v => company.set('vat', v.target.value)" :disabled="!editMode">
                    </li>

                </ul>
            </div>
            <div class="col-md-6">
                <ul class="list-group">
                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">
                        <span class="font-weight-700">{{ translate('Logo', 'clients') }}</span>
                        <button class="btn btn-danger rounded-pill py-0" @click="company.data.logo = null">{{ translate('Delete', 'clients') }}</button>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">
                        <image-field
                          class="picker-secondary w-100"
                          :field-props="{}"
                          v-model="company.data.logo"
                          @input="v => company.set('logo', v).save()"
                        />
                    </li>
                </ul>
                <ul class="list-group mt-3" v-for="manager in managers">
                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">
                        <span
                            class="font-weight-700">{{ translate('Contact person or Customer', 'clients') }}</span>
                        <button class="btn btn-primary rounded-pill py-0" v-if="editMode" @click="save">{{ translate('Save changes', 'global') }}</button>
                        <button class="btn border rounded-pill py-0" v-else @click="editMode = true">{{ translate('Edit', 'clients') }}</button>
                    </li>
                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">
                        <div class="d-flex flex-column">
                            <div>
                                <input v-model="manager.first_name" class="bg-white mr-2 mb-2"
                                       :class="editMode === true ? 'border rounded' : 'border-0'" :placeholder="translate('Contact person first name', 'entities')"
                                       @input="v => company.set('contact_person_first_name', v.target.value)"
                                       :disabled="!editMode">
                                <input v-model="manager.last_name" class="bg-white mb-2"
                                       :class="editMode === true ? 'border rounded' : 'border-0'" :placeholder="translate('Contact person last name', 'entities')"
                                       @input="v => company.set('contact_person_last_name', v.target.value)"
                                       :disabled="!editMode">
                            </div>
                            <input v-model="manager.email" class="bg-white"
                                   :class="editMode === true ? 'border rounded' : 'border-0'" :placeholder="translate('Contact person email', 'clients')"
                                   @input="v => company.set('contact_person_email', v.target.value)"
                                   :disabled="!editMode">
                        </div>
                    </li>
                </ul>
            </div>

<!--            <div class="col-md-6">-->
<!--                <ul class="list-group border mt-5">-->
<!--                    <li class="list-group-item d-flex align-items-center justify-content-between py-2">-->
<!--                        <span-->
<!--                            class="font-weight-700">{{ translate('clients.blockz_sales') }}</span>-->
<!--                    </li>-->
<!--                    <li class="list-group-item d-flex align-items-center justify-content-between">-->
<!--                        <div class="d-flex flex-column">-->
<!--                            <span>Diana Smiley</span>-->
<!--                            <span class="cont_personEmail">diana.smiley@company.com</span>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->

            <div class="col-md-12">
                <div class="delete_account">
                    <hr>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-between align-items-center p-2">
                            <div>
                                <p>{{ translate('Delete account', 'clients') }}</p>
                                <p id="note">{{ translate('Delete account text', 'clients') }}</p>
                            </div>
                            <button class="btn btn-danger rounded-pill">{{ translate('Delete', 'clients') }}</button>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>

            <div class="col-12 text-right">
                <router-link :to="`/companies`" class="btn btn-white rounded-pill px-4">
                  {{ translate('Go back', 'global') }}
                </router-link>
            </div>

            <modal name="edit_client"
                   :pivot-x="1"
                   :pivot-y="0"
                   overlayTransition="wait"
                   transition="slide-fade"
                   height="100%"
                   width="85%"
                   :scrollable="true"
                   :adaptive="true"
            >
                <!--            <entity-form v-if="formData" @reload="$modal.hide('edit_client')" entity="clients" v-model="formData"></entity-form>-->
            </modal>
        </div>
    </div>
</template>

<script>
import EntityForm from '@/modules/erp_entities/components/page/form/EntityForm'
import ImageField from "@/modules/erp_entities/components/page/form/fields/ImageField.vue"

export default {
    name: 'ClientsGeneral',
    components: {
      EntityForm, ImageField,
    },
    props: {
        company: {
            required: true
        },
		creator: {
        	required: true
		},
		managers: {
        	required: true
		}
    },
    data() {
        return {
            editMode: false
        }
    },
    methods: {
        async save() {
            try {
                await this.company.save()
                this.$toast.requestSuccess('put', 'Company') // explicit put
                this.editMode = false
            } catch (errors) {
                this.$toast.errorGeneral()
            }
        }
    },
	// data(){
    //     return {
    //         formData:  {
    //             default_properties: this.client.data.options,
    //             structure: this.columns,
    //             target: `companies/${this.client.data.id}`,
    //             mode: 'edit'
    //         }
    //     }
    // }
}
</script>

<style>
#note {
    font-weight: 600;
}

#drop_file {
    border: 2px dotted grey;
    border-radius: 2px;
}

.cont_personEmail {
    color: grey;
    font-weight: 500;
    word-wrap: break-word;
}
</style>
