<template>
    <!-- <div class=""> -->
    <div class="row">
        <div class="col-md-12">
            <div class="input-group w-100 input-group-lg page-builder-modal-input">
                <input type="text" class="form-control" :placeholder="translate('Search', 'clients')">
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-3">
            <h6 class="font-weight-700 mb-0">{{ translate('Active', 'clients') }}</h6>
            <hr>
        </div>
        <div class="col-md-12">
            <div class="d-flex flex-column bg-white rounded mt-3">

                <div class="table-responsive">
                </div>
                <div class="card m-0">
                    <table class="table">
                        <thead class="thead-light">
                        <tr>
                            <th class="font-weight-700">{{ translate('Subscriptions', 'entities') }}</th>
                            <th class="font-weight-700">{{ translate('Price', 'entities') }}</th>
                            <th class="font-weight-700">{{ translate('Yearly price', 'entities') }}</th>
                            <th class="font-weight-700">{{ translate('Trial end', 'entities') }}</th>
                            <th class="font-weight-700">{{ translate('Status', 'entities') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(sub, si) in subscriptions.data">
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.plan_options.name }}</span>
                                    <small class="text-muted">{{ sub.plan.inventory_type }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.price }} {{ sub.plan_options.currency }}</span>
                                    <small class="text-muted">{{ sub.cycle_start }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.yearly_price }} {{ sub.plan_options.currency }}</span>
                                    <small class="text-muted">{{ sub.plan_options.payment_terms }} {{ translate('years', 'clients') }}</small>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700">{{ sub.trial_end }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex flex-column">
                                    <span class="font-weight-700 active-sub d-flex justify-content-center" v-if="String(sub.status) == '0'">{{ translate('Inactive', 'clients') }}</span>
                                    <span class="font-weight-700 inactive-sub d-flex justify-content-center" v-if="String(sub.status) == '1'">{{ translate('Active', 'clients') }}</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-3">
            <h6 class="font-weight-700 mb-0">{{ translate('Expired', 'clients') }}</h6>
            <hr>
        </div>
    </div>
    <!-- </div> -->
</template>
<script>
export default {
    data() {
        return {
            subscriptions: null
        }
    },
    async created() {
        let subscriptions = await this.erp.ext.request.axiosInstance.get('/modules/subscriptions?perpage=5&column=created_at&direction=desc',
            {
                headers: {
                    company: this.$route.params.id
                }
            })
        subscriptions.data.data.forEach(rec => {
            rec.plan_options = rec.plan.options.optionsToObject()
        })
        this.subscriptions = subscriptions.data
    },
}
</script>
<style scoped lang="scss">
.active-sub {
	background-color: #cdf7e5;
	color: #0a6d3e;
	border-radius: 10px;
}

.inactive-sub {
	background-color: #f9d7dd;
	color: #ed7b90;
	border-radius: 10px;
}

input[type=text] {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;

    &::placeholder {
        color: #9da3a9;
    }

    &:focus {
        border-color: none;
    }

    &:active {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid #fff055;
    }

    &:hover {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid #9da3a9;
    }
}

.input-group-append {
    .input-group-text {
        border: 1px solid #e6e6e6;
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
    }

    &::placeholder {
        color: #9da3a9;
    }

    &:focus {
        outline: none;
    }

    &:active {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid #fff055;
    }

    &:hover {
        background: $primary-panel-offset-color;
        color: $body-color;
        border: 1px solid #9da3a9;
    }
}
</style>
