<template>
    <div>
        <div class="row" v-if="value && value.comments">
            <div class="col-md-12">
                <div class="d-flex bg-white rounded p-3">
                    <div class="d-flex">
                        <img class="avatar rounded-circle p-1" :src="$store.state.system.authData.user.avatar">
                        <text-editor-inline v-model="comment" />
                    </div>
                </div>
                <div class="d-flex mt-3 justify-content-end">
                    <button class="btn btn-primary rounded-pill" @click="addComment">{{ translate('Submit', 'global') }}</button>
                </div>
            </div>
            <div class="col-md-12" v-for="(comment, ci) in value.comments">
                <div class="d-flex flex-column bg-white rounded mt-3 p-3">
                    <div class="d-flex align-items-center" v-if="comment.user">
                        <img class="avatar rounded-circle" :src="comment.user.avatar">
                        <div class="d-flex flex-column ml-3">
                            <span class="font-weight-700">{{ comment.user.first_name }} {{ comment.user.last_name }}</span>
                            <small class="text-muted"><i class="fa fa-clock"></i> {{ formatDate(comment.created_at, null, 'YYYY MMMM DD - HH:mm')}}</small>
                        </div>
                    </div>
                    <div class="mt-2" v-html="comment.comment"></div>
                </div>
            </div>
            <div class="d-flex px-4 pb-3">

            </div>
        </div>
    </div>
</template>
<script>
import TextEditorInline from "@/modules/erp_entities/components/TextEditorInline.vue"

export default {
    components: {
      TextEditorInline,
    },
    data() {
        return {
            comment: ''
        }
    },
    props: {
        value: {
            required: true
        }
    },
    methods: {
        addComment() {
            let data = {
                options: {
                    object_id: this.$route.params.id,
                    object_type: 'company',
                    comment: this.comment
                }
            }
            this.erp.ext.request.axiosInstance.post('/modules/comments/store', data).then(res => {
              this.$toast.requestSuccess('post', 'Comment')
            })
        }
    },
    mounted() {
        if (this.value) {
            this.value.comments.forEach(comment => {
                comment.user = this.value.users.filter(user => user.id === comment.user_id)[0]
            })
        }
    }
}
</script>