<template>
    <div>
        <v-infinite-progressbar v-if="loading"></v-infinite-progressbar>
        <div class="container">
            <div v-if="company && company.data && company_data">
                <page-title :page-title="company.data.name" :borderBottom="false"></page-title>
                <tabs class="clients-show">
                    <tab name="general" :selected="true">
                        <clients-general :creator="creator" :managers="managers" :company="company"/>
                    </tab>
                    <tab name="billing">
                        <clients-billing/>
                    </tab>
                    <tab name="members">
                        <clients-members v-model="company_data" />
                    </tab>
                    <tab name="subscriptions">
                        <clients-subscriptions/>
                    </tab>
                    <tab name="history logger">
                        <clients-history-logger v-model="company_data.data" />
                    </tab>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import ClientsBilling from './ClientsBilling'
import ClientsHistoryLogger from './ClientsHistoryLogger'
import ClientsMembers from './ClientsMembers'
import ClientsGeneral from './ClientsGeneral'
import ClientsSubscriptions from './ClientsSubscriptions'
import ErpModel from '@/modules/erp_framework/services/ErpModel'


export default {
    name: 'ClientsShow',
    components: {
        ClientsBilling,
        ClientsHistoryLogger,
        ClientsMembers,
        ClientsGeneral,
        ClientsSubscriptions,
    },
    data() {
        return {
            company: null,
			managers: null,
			creator: null,
            company_data: null,
            loading: false
        }
    },
    async mounted() {
        this.loading = true
        this.company = await ErpModel.find('companies', this.$route.params.id)
        this.company_data = await this.erp.ext.request.find('companies', this.$route.params.id)
		this.managers = this.company_data.data.managers
		this.creator = this.company_data.data.sales_representative_id
        this.loading = false
    },
}
</script>

<style>

</style>
