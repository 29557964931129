<template>
    <div v-if="value && value.data">
        <div class="card bg-white">
            <div class="card-header d-flex align-items-center justify-content-between">
                <h6 class="mb-0">{{ translate('Members', 'clients') }}</h6>
                <button @click="$modal.show('create_user')" class="btn btn-primary rounded-pill">{{ translate('Invite member', 'clients') }}</button>
            </div>
            <div class="card-body">
                <div class="card-body__border" v-for="(user, ui) in value.data.users">
                    <div class="d-flex justify-content-between py-3">
                        <div class="d-flex align-items-center">
                            <img class="avatar rounded-circle" :src="user.avatar" alt="">
                            <div class="d-flex flex-column ml-3">
                                <span>{{user.first_name}} {{user.last_name}}</span>
                                <small class="text-muted">{{user.email}}</small>
                            </div>
                        </div>
						<div class="d-flex align-items-center">
							<cta></cta>
						</div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="create_user"
               :pivot-x="1"
               :pivot-y="0"
               transition="slide-fade"
               overlayTransition="wait"
               height="100%"
               width="85%"
               @before-close="formData = initialFormData"
               :adaptive="true">
            <user-form :roles="value.additionalData.roles" v-if="value && value.additionalData.roles" v-model="formData" :company="$route.params.id"></user-form>
        </modal>
    </div>
</template>

<script>
    import UserForm from "@/modules/users_management/views/users/UserForm";
    export default {
        name: 'Members',
        components: {
            UserForm
        },
        props: {
            value: {
                required: true,
            }
        },
        data() {
            return {
                formData: null
            }
        },
        created() {
            this.formData = {...this.initialFormData}
        },
        computed: {
            initialFormData() {
                return {
                    avatar: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    phone: '',
                    birthdate: '',
                    roles: '',
                    timezone: '',
                    locale: '',
                    company_id: ''
                }
            }
        }
    }
</script>
